<template>
  <b-container class="p-5">
    <b-row class="h-25" align-v="center">
      <b-col lg="12" class="text-center h2">4GBox OTA Platform</b-col>
    </b-row>
    <b-row align-v="center">
      <b-col lg="4" offset-lg="4"><LoginForm /></b-col>
    </b-row>
  </b-container>
</template>

<script>
// @ is an alias to /src
import LoginForm from "@/components/LoginForm.vue";

export default {
  name: "LoginView",
  components: {
    LoginForm,
  },
  async mounted() {
    console.log(process.env.NODE_ENV);
    console.log(process.env.VUE_APP_API_URL);
  },
};
</script>

<style scoped lang="scss"></style>
