import Vue from "vue";
import VueRouter from "vue-router";
import FileListView from "@/views/FileListView.vue";
import LoginView from "@/views/LoginView.vue";
import TaskListView from "@/views/TaskListView.vue";
import TaskView from "@/views/TaskView.vue";
import GSettingView from "@/views/GSettingView.vue";
import * as auth from "@/utils/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/tasklist",
    name: "tasklist",
    component: TaskListView,
    beforeEnter: (to, from, next) => {
      if (localStorage.username == "taskcreator") {
        next({ name: "taskWithParam" });
      } else {
        next();
      }
    },
  },
  {
    path: "/task",
    name: "taskWithParam",
    component: TaskView,
    beforeEnter: (to, from, next) => {
      if (localStorage.username == "taskpublisher") {
        next({ name: "tasklist" });
      } else {
        next();
      }
    },
  },
  {
    path: "/task/:partitionKey/:rowKey",
    name: "task",
    component: TaskView,
  },
  {
    path: "/gsetting",
    name: "gsetting",
    component: GSettingView,
  },
  {
    path: "/filelist",
    name: "filelist",
    component: FileListView,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/HomeView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(to);
  // If logged in, or going to the Login page.
  if (auth.isLoginExpired() == false || to.name === "login") {
    next();
  } else {
    // Not logged in, redirect to login.
    next({ name: "login" });
  }
});

export default router;
