import moment from "moment";

const loginExpiredAt = "loginExpiredAt";
// const roles = [
//   { id: 1, rolename: "Administrators", accounts: [1, 2] },
//   { id: 2, rolename: "TaskCreator", accounts: [3] },
//   { id: 3, rolename: "TaskPublisher", accounts: [4] },
// ];
// const accounts = [
//   { id: 1, username: "0987654321", password: "otaP@ssw0rd" },
//   { id: 2, username: "sdcadmin", password: "Sdc@dm1n28216728" },
//   { id: 3, username: "creator", password: "creat0rP@ssw0rd" },
//   { id: 4, username: "publisher", password: "publ1sherP@ssw0rd" },
// ];

export function login(username, password) {
  let isValidUsernamePassword = false;

  if (username === "0987654321" && password === "otaP@ssw0rd") {
    isValidUsernamePassword = true;

    // localStorage.setItem("username", username);
    // localStorage.setItem(
    //   loginExpiredAt,
    //   moment().add(60, "minute").valueOf() // keep 1 hour
    // );
    // retVal = true;
  } else if (username === "taskcreator" && password === "creat0rP@ssw0rd") {
    isValidUsernamePassword = true;
  } else if (username === "taskpublisher" && password === "publ1sherP@ssw0rd") {
    isValidUsernamePassword = true;
  }

  if (isValidUsernamePassword) {
    localStorage.setItem("username", username);
    localStorage.setItem(
      loginExpiredAt,
      moment().add(60, "minute").valueOf() // keep 1 hour
    );

    return true;
  } else {
    return false;
  }
}

export function logout() {
  localStorage.removeItem(loginExpiredAt);
}

export function isLoginExpired() {
  let retVal = true;
  const expireAt = localStorage.getItem("loginExpiredAt");

  if (expireAt) {
    retVal = moment().valueOf() >= expireAt;
  }

  return retVal;
}
