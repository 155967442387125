import axios from "axios";

// const mock = [
//   {
//     id: 1,
//     filename: "aaa",
//     size: 123456,
//     checksum: "aaa",
//     uploadDate: 1667292528265,
//   },
//   {
//     id: 2,
//     filename: "bbb",
//     size: 123456,
//     checksum: "bbb",
//     uploadDate: 1667292528265,
//   },
// ];

// const mockedit = {
//   selected: "Fence", //SingleIMEI IMEISuffix All
//   file: null,
//   conditions: [
//     {
//       name: "Single IMEI",
//     },
//     {
//       name: "IMEI suffix",
//     },
//     {
//       name: "Coordinate",
//     },
//   ],
//   singleIMEI: "dsasd",
//   IMEISuffix: "wwww",
//   lat: "123",
//   lon: "456",
//   radius: "789",
// };
export const fetchList = async () => {
  axios.get("http://localhost:3000/task").then((res) => {
    return res.data.data;
  });
  // return new Promise((resolve) => {

  //   // resolve(mock);
  //   // axios.get("https://jsonplaceholder.typicode.com/todos").then((res) => {
  //   //   resolve(res.data);
  //   // });
  // });
};

// export const fetchById = async (id) => {
//   return new Promise((resolve) => {
//     console.log(id);
//     resolve(mockedit);
//   });
// };

export const updateById = async (form) => {
  console.log(form);
  return new Promise((resolve) => {
    let formData = new FormData();
    Object.keys(form).forEach((key) => {
      formData.append(key, form[key]);
    });
    axios
      .post("http://localhost:3000/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        resolve(res.data);
      });

    /*  var url = "http://localhost:3000/csi/v1/upload";
    // var data = { username: "example" };

    fetch(url, {
      method: "POST", // or 'PUT'
      body: JSON.stringify(formData), // data can be `string` or {object}!
      headers: new Headers({
        "Content-Type": "multipart/form-data",
      }),
    })
      .then((res) => res.json())
      .catch((error) => console.error("Error:", error))
      .then((response) => console.log("Success:", response));
 */
    resolve("form");
  });
};

export const deleteById = async (id) => {
  return new Promise((resolve) => {
    resolve(`deleteok${id}`);
  });
};
