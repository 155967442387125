<template>
  <b-container class="p-5">
    <b-row class="p-2">
      <b-col
        lg="12"
        class="text-left font-weight-bold border-bottom border-dark"
        >File Management</b-col
      >
    </b-row>
    <b-row class="p-2 font-weight-bold">
      <b-col lg="4" sm="4">Filename</b-col>
      <b-col lg="2" sm="3">Size(byte)</b-col>
      <b-col lg="2" sm="0">Checksum</b-col>
      <b-col lg="2" sm="3">Upload Date</b-col>
      <b-col lg="2" sm="2"></b-col>
    </b-row>
    <b-row class="p-2" v-for="file in this.files" :key="file.filename">
      <template>
        <b-col lg="4" sm="4">{{ file.filename }}</b-col>
        <b-col lg="2" sm="3">{{ file.size }}</b-col>
        <b-col lg="2" sm="0">{{ file.checksum }}</b-col>
        <b-col lg="2" sm="3">{{
          new Date(file.uploadDate).toLocaleDateString("zh-TW")
        }}</b-col>
        <b-col lg="2" sm="2">
          <font-awesome-icon
            icon="pen-to-square"
            class="pr-2"
            role="button"
            @click="editFile(file)"
          />
          <b-icon
            icon="trash"
            variant="danger"
            role="button"
            @click="deleteFile(file)"
          ></b-icon>
        </b-col>
      </template>
    </b-row>

    <b-modal v-model="showModal" @ok="handleOk" id="modal-1" title="刪除">
      <p class="my-4">{{ `確定要刪除${selectItem.filename}嗎?` }}</p>
    </b-modal>
  </b-container>
</template>

<script>
import { fetchList, deleteById } from "../services/rest";

export default {
  name: "FileListView",
  components: {},
  data() {
    return {
      files: [],
      showModal: false,
      selectItem: {},
    };
  },
  async mounted() {
    // const stat = fs.statSync("../upload/offcial240.bin");
    // console.log(stat);
    // const buf = fs.readFileSync("../upload/offcial240.bin");
    //await uploadFile("../upload/offcial240.bin");
    // Vue.axios.get("http://localhost:3000/file").then((res) => {
    //   console.log(res.data);
    //   this.files = res.data;
    // });
    this.files = await fetchList();
  },
  methods: {
    editFile(file) {
      this.$router.push({ path: `/strategy?id=${file.id}` });
    },
    deleteFile(file) {
      this.showModal = true;
      this.selectItem = file;
    },
    async handleOk() {
      let r = await deleteById(this.selectItem.id);
      console.log(r);
    },
  },
};
</script>

<style scoped lang="scss"></style>
