import "@babel/polyfill";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import "mutationobserver-shim";
import Vue from "vue";
import VueAxios from "vue-axios";
import App from "./App.vue";
import "./plugins/bootstrap-vue";
import router from "./router";
import store from "./store";

library.add(faPenToSquare);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;
Vue.use(VueAxios, axios);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
