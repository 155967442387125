<template>
  <b-input-group class="w-100">
    <template #prepend>
      <b-form-select v-model="rule.allow" :options="taskAllow" />
      <b-form-select v-model="rule.ruleType" :options="taskRuleTypes" />
    </template>

    <b-form-input v-model="rule.ruleValue" placeholder="" />

    <template #append>
      <b-input-group-text @click="remove(rule)">
        <b-icon icon="x" />
      </b-input-group-text>
    </template>
  </b-input-group>
</template>

<script>
export default {
  name: "TaskRuleComponent",
  props: ["id", "allow", "ruleType", "ruleValue"],
  data() {
    return {
      // value & text mapping MUST be same with TaskRuleType in webapi
      taskRuleTypes: [
        { value: 0, text: "All" },
        { value: 1, text: "IMEI" },
        { value: 2, text: "部分IMEI 更新" },
      ],
      taskAllow: [
        { value: false, text: "黑名單" },
        { value: true, text: "白名單" },
      ],
      // rule: {
      //   id: this.id,
      //   allow: this.allow,
      //   ruleType: this.ruleType,
      //   ruleValue: this.ruleValue,
      // },
    };
  },
  computed: {
    rule() {
      return this.$store.state.ruleComponents.find((m) => m.id == this.id);
    },
  },
  methods: {
    remove(rule) {
      this.$store.commit("removeRuleComponent", rule.id);
    },
  },
};
</script>

<style></style>
