<template>
  <b-form @submit="onSubmit">
    <b-form-group label="Email/Mobile" label-for="username">
      <b-form-input
        type="text"
        id="username"
        placeholder="Email/Mobile"
        v-model="username"
        required
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Password" label-for="password">
      <b-form-input
        type="password"
        id="password"
        placeholder="Password"
        v-model="password"
        required
      ></b-form-input>
    </b-form-group>
    <b-button type="submit" variant="primary" class="w-100">Submit</b-button>
  </b-form>
</template>

<script>
import { login } from "@/utils/auth";
export default {
  name: "LoginForm",
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    onSubmit() {
      if (login(this.username, this.password) == true) {
        this.$router.push({ name: "tasklist" });
      }
    },
  },
};
</script>

<style></style>
