import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // user: null,
    ruleComponents: [],
  },
  getters: {},
  mutations: {
    clearRuleComponents(state) {
      state.ruleComponents = [];
    },
    removeRuleComponent(state, id) {
      let idx = state.ruleComponents.findIndex((r) => r.id == id);
      state.ruleComponents.splice(idx, 1);
    },
  },
  actions: {},
  modules: {},
});
