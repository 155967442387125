<template>
  <div>
    <b-container class="p-5">
      <b-alert
        :show="dismissCountDown"
        dismissible
        variant="warning"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="dismissCountDown = dismissCountDown"
      >
        {{ message }}
      </b-alert>

      <b-row class="p-2">
        <b-col
          lg="12"
          class="text-left font-weight-bold border-bottom border-dark"
          >每次僅能開啟一組OTA 任務</b-col
        >
      </b-row>

      <b-row class="p-2">
        <b-col lg="12">
          <b-input-group prepend="產品名稱" class="mb-2 mr-sm-2 mb-sm-0">
            <b-form-select
              v-model="taskInfo.productionName"
              :options="productions"
            ></b-form-select>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row class="p-2">
        <b-col lg="12">
          <b-input-group prepend="任務名稱" class="mb-2 mr-sm-2 mb-sm-0">
            <b-form-input
              v-model="taskInfo.taskName"
              placeholder="User friendly name"
              required
            ></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row class="p-2">
        <b-col lg="12">
          <b-input-group prepend="任務描述">
            <b-form-textarea
              v-model="taskInfo.comment"
              placeholder="Task comment"
            ></b-form-textarea>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row class="p-2">
        <b-col lg="12">
          <b-input-group prepend="檔案名稱" class="mb-2 mr-sm-2 mb-sm-0">
            <b-form-file
              v-model="otaFile"
              :state="Boolean(otaFile)"
              placeholder="選擇更新檔或將更新檔拖到這裡..."
              drop-placeholder="將更新檔拖到這裡..."
              required
            ></b-form-file>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row class="p-2">
        <b-col lg="12">
          <b-input-group prepend="版本號" class="mb-2 mr-sm-2 mb-sm-0">
            <b-form-input
              v-model="taskInfo.version"
              placeholder=""
              required
            ></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row class="p-2">
        <b-col
          lg="12"
          class="mt-3 text-left font-weight-bold border-bottom border-dark"
          >任務規則</b-col
        >
      </b-row>

      <b-row class="p-2">
        <b-alert show class="w-100">
          <ol>
            <li>預設值: 允許所有裝置更新 (Allow all)</li>
            <li>如果未設定任何規則，則使用預設值</li>
            <li>規則由上而下依順序判斷，如果無任何相符的規則，則使用預設值</li>
            <li>如果選擇1個以上的imei用逗號","分隔</li>
          </ol>
          <div>
            範例：只允許IMEI=00000000000000,
            111111111111111更新。須設定兩條規則：
          </div>
          <div>1. 白名單, IMEI, 00000000000000,111111111111111</div>
          <div>2. 黑名單, All</div>
        </b-alert>
      </b-row>

      <b-row class="p-2">
        <b-col lg="12">
          <b-button variant="info" @click="add">新增規則</b-button>
        </b-col>
      </b-row>

      <b-row class="p-2">
        <b-col lg="12">
          <component
            v-for="rule in ruleComponents"
            v-bind:is="rule.type"
            :key="rule.id"
            :id="rule.id"
            :allow="rule.allow"
            :ruleType="rule.ruleType"
            :ruleValue="rule.ruleValue"
          ></component>
        </b-col>
      </b-row>

      <b-row class="pt-5 pb-5">
        <b-col offset="8" lg="2">
          <b-button variant="info" class="w-100" @click="goTaskList"
            >Cancel</b-button
          >
        </b-col>
        <b-col lg="2">
          <b-button variant="success" class="w-100" @click="saveTask"
            >Save</b-button
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "GSettingView",
  created() {},
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss"></style>
