<template>
  <b-container class="p-5">
    <b-row class="p-2">
      <b-input-group prepend="產品名稱" class="mb-2 mr-sm-2 mb-sm-0">
        <b-form-select
          v-model="selectedProduction"
          :options="productions"
          @change="getTasks"
        ></b-form-select>
      </b-input-group>
    </b-row>

    <b-row class="p-2">
      <b-col lg="4" class="text-left font-weight-bold border-bottom border-dark"
        >Tasks</b-col
      >
      <b-col
        lg="8"
        class="text-right font-weight-bold border-bottom border-dark"
      >
        <b-button size="sm" variant="info" @click="createTask">Create</b-button>
      </b-col>
    </b-row>
    <b-row class="p-2 font-weight-bold">
      <b-col lg="1">啟用</b-col>
      <b-col lg="1">產品名稱</b-col>
      <b-col lg="2">任務名稱</b-col>
      <b-col lg="3">檔案名稱</b-col>
      <b-col lg="1">檔案大小</b-col>
      <b-col lg="1">版本</b-col>
      <b-col lg="2">建立日期</b-col>
      <b-col lg="1"></b-col>
    </b-row>
    <b-row class="p-2" v-for="task in this.tasks" :key="task.rowKey">
      <!-- <template> -->
      <b-col lg="1">
        <!-- {{ task.enabled }} -->
        <input
          type="checkbox"
          :name="task.rowKey"
          :checked="task.enabled"
          v-model="task.enabled"
          @change="changeEnabledTask($event.target.name)"
        />
      </b-col>
      <b-col lg="1">{{ task.productionName }}</b-col>
      <b-col lg="2">{{ task.taskName }}</b-col>
      <b-col lg="3">{{ task.fileName }}</b-col>
      <b-col lg="1">{{ task.fileSize }}</b-col>
      <b-col lg="1">{{ task.fileVersion }}</b-col>
      <b-col lg="2">{{
        new Date(task.createdAt).toLocaleDateString("zh-Hans")
      }}</b-col>
      <b-col lg="1">
        <font-awesome-icon
          icon="pen-to-square"
          class="pr-2"
          role="button"
          @click="editTask(task)"
        />
        <b-icon
          icon="trash"
          variant="danger"
          role="button"
          @click="deleteTask(task)"
        ></b-icon>
      </b-col>
      <!-- </template> -->
    </b-row>

    <b-modal v-model="showModal" @ok="handleOk" id="modal-1" title="刪除">
      <p class="my-4">{{ `確定要刪除${selectedTask.taskName}嗎?` }}</p>
    </b-modal>
  </b-container>
</template>

<script>
import axios from "axios";

export default {
  name: "FileListView",
  components: {},
  data() {
    return {
      tasks: [],
      showModal: false,
      selectedTask: {},
      productions: ["S4GB", "STMB4000"],
      selectedProduction: "",
    };
  },
  async mounted() {
    this.selectedProduction = "S4GB";
    this.getTasks(this.selectedProduction);
  },
  methods: {
    parseBooleansInObj(object) {
      const newObject = { ...object };
      Object.keys(newObject).forEach((objKey) => {
        let value = newObject[objKey];
        value = value === "true" ? true : value === "false" ? false : value;
        newObject[objKey] = value;
      });
      return newObject;
    },
    getTasks() {
      console.log(
        `${process.env.VUE_APP_API_URL}/task/${this.selectedProduction}`
      );
      axios
        .get(`${process.env.VUE_APP_API_URL}/task/${this.selectedProduction}`, {
          headers: { Authorization: process.env.VUE_APP_API_KEY },
        })
        .then((res) => {
          const arr = res.data.data;
          const newArray = [];

          arr.forEach((r) => {
            newArray.push(this.parseBooleansInObj(r));
          });

          this.tasks = newArray;
        });
    },
    changeEnabledTask(rowKey) {
      if (this.selectedProduction == "") {
        return;
      }

      const selectedTask = this.tasks.find(
        (t) => t.partitionKey == this.selectedProduction && t.rowKey == rowKey
      );

      axios
        .patch(
          `${process.env.VUE_APP_API_URL}/task/${selectedTask.partitionKey}/${selectedTask.rowKey}/${selectedTask.enabled}`,
          selectedTask,
          { headers: { Authorization: process.env.VUE_APP_API_KEY } }
        )
        .then((res) => {
          console.log(res.data);
          // this.$router.go();
        });
    },
    createTask() {
      this.$router.push("/task");
    },
    editTask(task) {
      console.log(task);
      //TODO
      // this.$router.push({ path: `/task/${task.partitionKey}/${task.rowKey}` });
    },
    deleteTask(task) {
      this.showModal = true;
      this.selectedTask = task;
    },
    async handleOk() {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/delete-task`,
          {
            partitionKey: this.selectedTask.partitionKey,
            rowKey: this.selectedTask.rowKey,
            currentUser: localStorage.getItem("username"),
          },
          {
            headers: {
              "content-type": "application/json",
              Authorization: process.env.VUE_APP_API_KEY,
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.$router.go();
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
